import * as React from 'react';
import Banner from '../../Components/Banner/Banner';
import Layout from '../../Components/Layout/Layout';
import './styles.scss';
const Privacy = () => {
  return (
    <Layout className='Privacy'>
      <Banner title='Privacy Policy' />
      <section className='Frame-Inner'>
        <h2>Information collected</h2>
        <p>
          Information is only collected through contact forms and donation
          forms. These are completly voluntary and by no means required to use
          the site. Information collected if provided are name, phone number,
          and email. This information will be kept among the coaches and will
          not be given to any outside sources
        </p>
        <p>
          There is no usage or analytic information collected from user's visits
        </p>
        <h2>How the Information is used</h2>
        <p>
          The information is used to contact the user back regarding any thing
          that they reached out for or to let them know futher information about
          upcoming golf outings they signed up for. If user donated to be a
          sponsor for the Brookville Wrestling program they will have their name
          or business name posted for recongnition.
        </p>
        <h2>Log Files</h2>
        <p>
          The Brookville Wrestling Alumni Association follows a standard
          procedure of using log files. These files log visitors when they visit
          websites. All hosting companies do this and a part of hosting
          services' analytics. The information collected by log files include
          internet protocol (IP) addresses, browser type, Internet Service
          Provider (ISP), date and time stamp, referring/exit pages, and
          possibly the number of clicks. These are not linked to any information
          that is personally identifiable. The purpose of the information is for
          analyzing trends, administering the site, tracking users' movement on
          the website, and gathering demographic information.
        </p>
        <h2>Cookies</h2>
        <p>No cookies are collected on this site.</p>
        <h2>Payments</h2>
        <p>
          Payments are completley handled by PayPal and there is no information
          out side of the name and value donated stored.{' '}
          <a href='https://www.paypal.com/us/webapps/mpp/ua/privacy-full'>
            PayPal's Privacy Policy
          </a>
        </p>
        <h2>Online Privacy Policy Only</h2>
        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in The Bedtime Project. This policy is not
          applicable to any information collected offline or via channels other
          than this website.
        </p>
        <h2>Consent</h2>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>
      </section>
    </Layout>
  );
};
export default Privacy;
